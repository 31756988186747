import { AxiosResponse } from 'axios'
import { http } from '../api'
import { Licence, LicenceGroup, LicenceIncrement } from '@/core/model';
import { IncrementLicence } from '../model/IncrementLicence';
import { LicenceReturn } from '../model/LicenceReturn';

const controller = 'admin/licence'

export async function getPaginate(page: number, size: number, search?: any[], sort?: any[]): Promise<any> {

    let stringSort: string | undefined = undefined
    let stringSearch: string | undefined = undefined;


    if (sort) {
        stringSort = "";

        sort.map(x => {
            if (stringSort) {
                stringSort += `&sort=${x}`
            } else {
                stringSort = `sort=${x}`;
            }
        });

    }

    if (search) {

        stringSearch = "";

        search.map(x => {
            if (stringSearch) {
                stringSearch += `&search=${x}`
            } else {
                stringSearch = `search=${x}`;
            }
        });
    }
    const { data } = await http.get<any, AxiosResponse>(`${controller}/list?page=${page}&size=${size}${(stringSearch ? `&${stringSearch}` : '')}${stringSort ? `&${stringSort}` : ''}`);
    return data;
}

export async function getBy(id: string): Promise<any> {

    const { data } = await http.get<any, AxiosResponse>(`${controller}/?id=${id}`)
    return data;
}
export async function getAll(ids?: number[] | undefined): Promise<any> {

    let stringId = '';

    if (ids) {
        ids.map(x => {
            if (stringId) {
                stringId += `&id=${x}`
            } else {
                stringId = `id=${x}`;
            }
        })
    }


    const { data } = await http.get<any, AxiosResponse>(`${controller}/getAll?${stringId}`);
    return data;

}
export async function create(payload: Licence): Promise<Licence> {

    const { data } = await http.post<Licence, AxiosResponse>(`${controller}/`, payload)
    return data;
}

export async function update(payload: Licence): Promise<Licence> {

    const { data } = await http.patch<Licence, AxiosResponse>(`${controller}/update`, payload);
    return data;
}

export async function deleted(id: number): Promise<boolean> {

    const { status } = await http.delete(`${controller}/?id=${id}`);

    return status === 204;
}

export async function getLicenceColaborator(collaboratorId: number) {
    const { data } = await http.get<any, AxiosResponse>(`${controller}/collaborator/${collaboratorId}`);
    return data;
}

export async function getLicenceAplication(applicationId: number) {
    const { data } = await http.get<any, AxiosResponse>(`${controller}/application/${applicationId}`);
    return data;
}

export async function increment(payload: IncrementLicence): Promise<Licence> {

    const { data } = await http.patch<Licence, AxiosResponse>(`${controller}/increment`, payload);
    return data;
}


export async function createLicenseGroup(payload: LicenceGroup): Promise<any> {
    const { data } = await http.post<any, AxiosResponse>(`${controller}/group`, payload)
    return data;
}

export async function returnLicense(payload: LicenceReturn): Promise<any> {

    const { data } = await http.patch<any, AxiosResponse>(`${controller}/group/return`, payload);
    return data;
}


export async function getAvailableToObtain(application_id: number, group_id: number, collaborator_id: number): Promise<any> {

    const { data } = await http.get<any, AxiosResponse>(`${controller}/availableToObtain?application_id=${application_id}&group_id=${group_id}&collaborator_id=${collaborator_id}`)
    return data;
}

// export async function patchCompanhia(payload: Companhia): Promise<AxiosResponse> {
//     return await http.patch(`${controller}/${payload.id}`, payload)
// }