import { AxiosResponse } from 'axios'
import { httpAuth } from '../api'

import { Credential } from '../model'
import { Login, RoleResponse } from '../model/LoginResponse';




export async function login(payload: Credential): Promise<Login> {
  const controller = 'auth/login'
  const { data } = await httpAuth.post<Login, AxiosResponse>(`${controller}`, {}, {
    auth: {
      username: payload.email,
      password: payload.password
    }
  });
  return data;
}


export async function resetPassword(email: string): Promise<boolean> {
  const controller = 'auth/reset_password'
  const { status, data } = await httpAuth.post<any, AxiosResponse>(`${controller}`, {
    email
  });
  return status == 204;
}


export async function getRoles(id: string): Promise<RoleResponse> {
  const controller = `user/${id}/role`;
  const { data } = await httpAuth.get<RoleResponse, AxiosResponse>(`${controller}`);
  return data;
}

export async function refreshToken() {

  const controller = '/auth/refresh_token';
  const { data } = await httpAuth.get<any, AxiosResponse>(`${controller}`);
  return data;
}

// export async function findUsers(): Promise<User[]> {
//   const controller = 'usuario/listar'
//   const { data } = await httpAuth.get<User[], AxiosResponse>(`${controller}`,);

//   data.map((x) => {
//     x.papeis = x.papelLista.map((y) => y.nome).join(', ');
//   });
//   return data;
// }

