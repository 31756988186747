import { AxiosResponse } from 'axios'
import { http } from '../api'
import { Group } from '../model';


const controller = 'admin/group'

export async function getPaginate(page: number, size: number, search?: any[], sort?: any[]): Promise<any> {

    let stringSort: string | undefined = undefined
    let stringSearch: string | undefined = undefined;


    if (sort) {
        stringSort = "";

        sort.map(x => {
            if (stringSort) {
                stringSort += `&sort=${x}`
            } else {
                stringSort = `sort=${x}`;
            }
        });

    }

    if (search) {

        stringSearch = "";

        search.map(x => {
            if (stringSearch) {
                stringSearch += `&search=${x}`
            } else {
                stringSearch = `search=${x}`;
            }
        });
    }
    const { data } = await http.get<any, AxiosResponse>(`${controller}/list?page=${page}&size=${size}${(stringSearch ? `&${stringSearch}` : '')}${stringSort ? `&${stringSort}` : ''}`);
    return data;
}

export async function getBy(id: string): Promise<Group> {

    const { data } = await http.get<Group, AxiosResponse>(`${controller}/?id=${id}`)
    return data;
}

export async function getAll(ids?: number[] | undefined): Promise<any> {

    let stringId = '';

    if (ids) {
        ids.map(x => {
            if (stringId) {
                stringId += `&id=${x}`
            } else {
                stringId = `id=${x}`;
            }
        })
    }


    const { data } = await http.get<any, AxiosResponse>(`${controller}/getAll?${stringId}`);
    return data;

}

export async function create(payload: Group): Promise<Group> {

    const { data } = await http.post<Group, AxiosResponse>(`${controller}/`, payload)
    return data;
}

export async function update(payload: Group): Promise<Group> {

    const { data } = await http.patch<Group, AxiosResponse>(`${controller}/update`, payload);
    return data;
}

export async function deleted(id: number): Promise<boolean> {

    const { status } = await http.delete(`${controller}/?id=${id}`);

    return status === 204;
}

export async function getAllCollaborator(id: number): Promise<any> {
    const { data } = await http.get<any, AxiosResponse>(`${controller}/collaborator/${id}/getAll`);
    return data;

}


// export async function patchGroup(payload: Group): Promise<AxiosResponse> {
//     return await http.patch(`${controller}/${payload.id}`, payload)
// }