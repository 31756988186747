import { AxiosResponse } from 'axios'
import { http } from '../api'
import { User } from '../model';

const controller = 'admin/user'

export async function getPaginate(page: number, size: number, search?: any[], sort?: any[]): Promise<any> {

  let stringSort: string | undefined = undefined
  let stringSearch: string | undefined = undefined;


  if (sort) {
    stringSort = "";

    sort.map(x => {
      if (stringSort) {
        stringSort += `&sort=${x}`
      } else {
        stringSort = `sort=${x}`;
      }
    });

  }

  if (search) {

    stringSearch = "";

    search.map(x => {
      if (stringSearch) {
        stringSearch += `&search=${x}`
      } else {
        stringSearch = `search=${x}`;
      }
    });
  }
  const { data } = await http.get<any, AxiosResponse>(`${controller}/list?page=${page}&size=${size}${(stringSearch ? `&${stringSearch}` : '')}${stringSort ? `&${stringSort}` : ''}`);
  return data;
}

export async function getAll(ids?: number[] | undefined): Promise<any> {

  let stringId = '';

  if (ids) {
    ids.map(x => {
      if (stringId) {
        stringId += `&id=${x}`
      } else {
        stringId = `id=${x}`;
      }
    })
  }


  const { data } = await http.get<any, AxiosResponse>(`${controller}/getAll?${stringId}`);
  return data;

}
export async function getBy(id: string): Promise<any> {

  const { data } = await http.get<any, AxiosResponse>(`${controller}/?externalId=${id}`)
  return data;
}

export async function create(payload: User): Promise<User> {

  const { data } = await http.post<User, AxiosResponse>(`${controller}/`, payload)
  return data;
}

export async function update(payload: User): Promise<any> {

  const { data } = await http.patch<any, AxiosResponse>(`${controller}/update`, payload);
  return data;
}

export async function deleted(UserId: number): Promise<boolean> {

  const { status } = await http.delete(`${controller}/?id=${UserId}`);

  return status === 204;
}


export async function validResponsibles(companyid?: number): Promise<any> {

  const { data } = await http.get<any, AxiosResponse>(`${controller}/validResponsibles${(companyid ? `?headquartersId=${companyid}` : '')}`);

  return data;
}

export async function getCollaboratorByGroups(groupId: number[]) {
  const queryParams = groupId.map(f => {
    return `group_id=${f}`
  }).join("&")
  const { data } = await http.get<any, AxiosResponse>(`${controller}/collaborators?${queryParams}`)
  return data;
}

export async function reactivate(id: number): Promise<any> {

  const { data } = await http.patch<any, AxiosResponse>(`${controller}/${id}/reactivate`);
  return data;
}