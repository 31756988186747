import { AxiosResponse } from 'axios'
import { http } from '../api'
import { LicenceGroup } from '@/core/model';
import { LicenceReturn } from '../model/LicenceReturn';

const controller = 'admin/licence/group'

export async function getPaginate(page: number, size: number, search?: any[], sort?: any[]): Promise<any> {

    let stringSort: string | undefined = undefined
    let stringSearch: string | undefined = undefined;


    if (sort) {
        stringSort = "";

        sort.map(x => {
            if (stringSort) {
                stringSort += `&sort=${x}`
            } else {
                stringSort = `sort=${x}`;
            }
        });

    }

    if (search) {

        stringSearch = "";

        search.map(x => {
            if (stringSearch) {
                stringSearch += `&search=${x}`
            } else {
                stringSearch = `search=${x}`;
            }
        });
    }
    const { data } = await http.get<any, AxiosResponse>(`${controller}/list?page=${page}&size=${size}${(stringSearch ? `&${stringSearch}` : '')}${stringSort ? `&${stringSort}` : ''}`);
    return data;
}

export async function create(payload: LicenceGroup): Promise<LicenceGroup> {

    const { data } = await http.post<LicenceGroup, AxiosResponse>(`${controller}/`, payload)
    return data;
}

export async function update(payload: LicenceGroup): Promise<LicenceGroup> {

    const { data } = await http.patch<LicenceGroup, AxiosResponse>(`${controller}`, payload);
    return data;
}

export async function returnLicence(payload: LicenceReturn): Promise<any> {
    const { data } = await http.patch(`${controller}/return/`, payload);
    return data;

}

export async function transferLicence(payload: any): Promise<any> {
    const { data } = await http.patch(`${controller}/transfer`, payload);
    return data;

}

export async function getByCollaborator(id: number): Promise<any> {
    const { data } = await http.get(`${controller}/collaborator/${id}`);
    return data;

}


export async function getByDevices(id: number,page: number, size: number,): Promise<any> {
    const { data } = await http.get(`${controller}/${id}/devices?page=${page}&size=${size}`);
    return data;

}
