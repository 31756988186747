import { AxiosResponse } from 'axios'
import { http } from '../api'
import { Company } from '@/core/model';

const controller = 'admin/company'

export async function getPaginate(page: number, size: number, search?: any[], sort?: any[]): Promise<any> {

    let stringSort: string | undefined = undefined
    let stringSearch: string | undefined = undefined;


    if (sort) {
        stringSort = "";

        sort.map(x => {
            if (stringSort) {
                stringSort += `&sort=${x}`
            } else {
                stringSort = `sort=${x}`;
            }
        });

    }

    if (search) {

        stringSearch = "";

        search.map(x => {
            if (stringSearch) {
                stringSearch += `&search=${x}`
            } else {
                stringSearch = `search=${x}`;
            }
        });
    }
    const { data } = await http.get<any, AxiosResponse>(`${controller}/list?page=${page}&size=${size}${(stringSearch ? `&${stringSearch}` : '')}${stringSort ? `&${stringSort}` : ''}`);
    return data;
}

export async function getBy(id: string): Promise<Company> {

    const { data } = await http.get<Company, AxiosResponse>(`${controller}?id=${id}`)
    return data;
}

export async function getAll(ids?: number[] | undefined): Promise<any> {

    let stringId = '';

    if (ids) {
        ids.map(x => {
            if (stringId) {
                stringId += `&id=${x}`
            } else {
                stringId = `id=${x}`;
            }
        })
    }


    const { data } = await http.get<any, AxiosResponse>(`${controller}/getAll?${stringId}`);
    return data;

}

export async function create(payload: Company): Promise<Company> {
    const { data } = await http.post<Company, AxiosResponse>(`${controller}/`, payload)
    return data;
}

export async function update(payload: Company): Promise<Company> {

    const { data } = await http.patch<Company, AxiosResponse>(`${controller}/update`, payload);
    return data;
}

export async function deleted(id: number): Promise<boolean> {

    const { status } = await http.delete(`${controller}/?id=${id}`);

    return status === 204;
}

export async function getAllHeadquarters(): Promise<any> {
    const { data } = await http.get<any, AxiosResponse>(`${controller}/headquarters/getAll`)
    return data;
}
export async function getSubsidiariesByHeadquartersId(id: number): Promise<any> {
    const { data } = await http.get<any, AxiosResponse>(`${controller}/headquarters/${id}/subsidiaries`);
    return data;
}

export async function getHeadquartersByUser(id: number): Promise<any> {
    const { data } = await http.get<any, AxiosResponse>(`${controller}/headquarters/user/${id}`)
    return data;
}

export async function responsibleCompanies():Promise<any> {
    const { data } = await http.get<any, AxiosResponse>(`${controller}/responsibleCompanies`)
    return data;
}