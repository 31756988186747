import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { Authentication } from '@/store';


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../pages/login/loginPage.vue'),
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../pages/home/homePage.vue'),
    meta: {
      master: 'Página principal',
      admin: 'Página principal',
      collaborator: 'Página principal',
      manager: 'Página principal',
      icon: 'HouseIcon',
      divider: true,
    },
  },
  {
    path: '/companies',
    name: 'Empresas',
    component: () => import('../pages/companys/CompanyPage.vue'),
    meta: {
      master: 'Empresas',
      admin: 'Matriz / Filiais',
      collaborator: 'Minha empresa',
      manager: 'Matriz / Filiais',
      icon: 'CompanyIcon',
    },
  },
  {
    path: '/users',
    name: 'Usuários',
    component: () => import('../pages/users/UsersPage.vue'),
    meta: {
      master: 'Usuários',
      admin: 'Usuários',
      manager: 'Usuários',
      icon: 'PeopleIcon',
    },
  },
  {
    path: '/groups',
    name: 'Grupos',
    component: () => import('../pages/groups/GroupPage.vue'),
    meta: {
      master: 'Grupos',
      admin: 'Grupos',
      manager: 'Grupos',
      collaborator: 'Meus Grupos',
      icon: 'GroupIcon',
    },
  },
  {
    path: '/devices',
    name: 'Dispositivos',
    component: () => import('../pages/devices/DevicePage.vue'),
    meta: {
      master: 'Dispositivos',
      admin: 'Dispositivos',
      manager: 'Dispositivos',
      collaborator: 'Dispositivos',
      icon: 'MonitorMobbileIcon',
    },
  },
  
  {
    path: '/licences',
    name: 'Licenças',
    component: () => import('../pages/licences/LicencePage.vue'),
    meta: {
      master: 'Licenças',
      admin: 'Licenças',
      manager: 'Licenças',
      collaborator: 'Licenças',
      icon: 'ArchiveBookIcon'
    },
  },
  {
    path: '/applicationsWithMostLicences',
    name: 'Aplicações com Mais Licenças',
    component: () => import('../pages/reports/ReportsPage.vue'),
    meta: {
      master: 'Aplicações com Mais Licenças',
      admin: 'Aplicações com Mais Licenças',
      manager: 'Aplicações com Mais Licenças',
      collaborator: 'Aplicações com Mais Licenças',
    },
  },
  {
    path: '/headquartersWithMostLicences',
    name: 'Matrizes com Mais Licenças',
    component: () => import('../pages/reports/ReportsPage.vue'),
    meta: {
      master: 'Matrizes com Mais Licenças',
      admin: 'Matrizes com Mais Licenças',
      manager: 'Matrizes com Mais Licenças',
      collaborator: 'Matrizes com Mais Licenças',
    },
  },
  {
    path: '/collaboratorsWithMostLicencesObtained',
    name: 'Licenças por Colaborador',
    component: () => import('../pages/reports/ReportsPage.vue'),
    meta: {
      master: 'Licenças por Colaborador',
      admin: 'Licenças por Colaborador',
      manager: 'Licenças por Colaborador',
      collaborator: 'Licenças por Colaborador',
    },
  },
  {
    path: '/collaboratorsLicenceUtilization',
    name: 'Aproveitamento por Colaborador',
    component: () => import('../pages/reports/ReportsPage.vue'),
    meta: {
      master: 'Aproveitamento por Colaborador',
      admin: 'Aproveitamento por Colaborador',
      manager: 'Aproveitamento por Colaborador',
      collaborator: 'Aproveitamento por Colaborador',
    },
  },
  {
    path: '/groupsLicenceUtilization',
    name: 'Aproveitamento por Grupo',
    component: () => import('../pages/reports/ReportsPage.vue'),
    meta: {
      master: 'Aproveitamento por Grupo',
      admin: 'Aproveitamento por Grupo',
      manager: 'Aproveitamento por Grupo',
      collaborator: 'Aproveitamento por Grupo',
    },
  },
  {
    path: '/licencesExpirationDateByCompany',
    name: 'Vencimento das Licenças',
    component: () => import('../pages/reports/ReportsPage.vue'),
    meta: {
      master: 'Vencimento das Licenças',
      admin: 'Vencimento das Licenças',
      manager: 'Vencimento das Licenças',
      collaborator: 'Vencimento das Licenças',
    },
  },
  {
    path: '/mostUsedManufacturerApplications',
    name: 'Aplicativos por Marca/Plataforma',
    component: () => import('../pages/reports/ReportsPage.vue'),
    meta: {
      master: 'Aplicativos por Marca/Plataforma',
      admin: 'Aplicativos por Marca/Plataforma',
      manager: 'Aplicativos por Marca/Plataforma',
      collaborator: 'Aplicativos por Marca/Plataforma',
    },
  },
  {
    path: '/licenceFirstLicencing',
    name: 'Obtencao e Uso da Licença',
    component: () => import('../pages/reports/ReportsPage.vue'),
    meta: {
      master: 'Obtencao e Uso da Licença',
      admin: 'Obtencao e Uso da Licença',
      manager: 'Obtencao e Uso da Licença',
      collaborator: 'Obtencao e Uso da Licença',
    },
  },
  {
    path: '/licence-group/:id',
    name: 'Licenças por Grupo',
    component: () => import('../pages/licences/group/LicenceGroup.vue'),
    meta: {
      master: 'Licenças por Grupo',
      admin: 'Licenças por Grupo',
      manager: 'Licenças por Grupo',
      collaborator: 'Minhas Licenças',
      icon: 'CpuIcon',
    },
  },
  {
    path: '/minha-licenca',
    name: 'Minha Licença',
    component: () => import('../pages/licences/group/LicenceGroup.vue'),
    meta: {
      collaborator: 'Minhas Licenças',
      icon: 'CpuIcon',
    },
  },
  {
    path: '/categories',
    name: 'Categorias',
    component: () => import('../pages/categorys/CategoryPage.vue'),
    meta: {
      master: 'Categorias',
      icon: 'Element3Icon',
    },
  },
  {
    path: '/applications',
    name: 'Loja',
    component: () => import('../pages/applications/ApplicationPage.vue'),
    meta: {
      master: 'Loja',
      admin: 'Loja',
      manager: 'Loja',
      collaborator: 'Loja',
      icon: 'CpuIcon',
    },
  },
  {
    path: '/application/:id',
    name: 'Versão da Aplicação',
    component: () => import('../pages/applications/version/ApplicationVersionPage.vue'),
    meta: {
      master: 'Versão da Aplicação',
      admin: 'Versão da Aplicação',
      manager: 'Versão da Aplicação',
      collaborator: 'Versão da Aplicação',
      icon: 'CpuIcon',
    },
  },
  {
    path: '/profile',
    name: 'Meus Dados',
    component: () => import('../pages/profile/ProfileData.vue'),
    meta: {
      master: 'Meus Dados',
      admin: 'Meus Dados',
      manager: 'Meus Dados',
      collaborator: 'Meus Dados',
    },
  },
  {
    path: '/mk',
    name: 'Mk',
    component: () => import('../pages/MK.vue'),
    meta: {
      master: 'MK',
      admin: 'MK',
      collaborator: 'MK',
      manager: 'MK'
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {

  Authentication.actions.ActionCheckToken();
  const token = Authentication.getters.getToken.value;
  const roles = Authentication.getters.getRoleMk.value;

  if (!token && to.name !== 'Login') {
    next({ name: 'Login' });
  } else if (token && to.path == "/") {
    next("/home")
  } else if (to.path != '/home' && to.path != '/' && to.path != '/profile') {
    if (token && roles && roles.data.functionalities.find((f) => to.path.includes(f.path))) {
      next(true);
    } else {
      next('/home');
    }
  } else next(true);
  // next(true);
});
export { routes };
export default router;
