import axios from 'axios'
import { Authentication } from '../../store'
import Swal from 'sweetalert2'
import router from "@/router"


const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

http.interceptors.request.use((config: any) => {
  if (config.headers === undefined)
    return
  if (Authentication.state.value.token) {
    config.headers.Authorization = `Bearer ${Authentication.state.value.token}`
  }

  return config
})

http.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const message = error?.response?.data?.message;
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      try {
        await Authentication.actions.refreshToken();
        originalRequest.headers['Authorization'] = `Bearer ${Authentication.state.value.token}`;
        originalRequest._retry = true;
        // Reenvia a solicitação original com o novo token
        return http(originalRequest);
      } catch (error) {

        Swal.fire({
          icon: "warning",
          title: "Atenção",
          text: "Sua sessão foi expirada, favor se logar novamente"
        }).then(() => {
          Authentication.actions.logout();
          router.push("/");
        });
      }
    }
    else if (error.response.status === 401) {
      Swal.fire({
        icon: "warning",
        title: "Atenção",
        text: "Sua sessão foi expirada, favor se logar novamente"
      }).then(() => {
        Authentication.actions.logout();
        router.push("/");
      });
    }
    else if (error.response.status === 400) {
      Swal.fire({
        icon: "warning",
        title: "Atenção",
        text: message
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Atenção",
        text: message ?? "Ocorreu um erro inesperado no sistema, por favor contacte o suporte"
      });
    }

    return Promise.reject(error)
  },
)


const httpAuth = axios.create({
  baseURL: process.env.VUE_APP_API_URL_AUTH,
  headers: {
    'Content-Type': 'application/json',
  },
})

httpAuth.interceptors.request.use((config: any) => {
  if (config.headers === undefined)
    return

  console.log(Authentication.state.value?.token)

  if (Authentication.state.value?.token) {
    config.headers.Authorization = `Bearer ${Authentication.state.value.token}`
  }

  return config
})

httpAuth.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error.response.status === 401) {

      Swal.fire({
        icon: "warning",
        title: "Atenção",
        text: "Sua sessão foi expirada, favor se logar novamente"
      }).then(() => {
        Authentication.actions.logout();
        router.push("/");
      });
    }
    else if (error.response.status === 403) {
      const message = error?.response?.data?.message;
      Swal.fire({
        icon: "warning",
        title: "Atenção",
        text: message
      });
    } else if (error.response.status === 400) {
      const message = error?.response?.data?.message;
      Swal.fire({
        icon: "warning",
        title: "Atenção",
        text: message
      });
    } else if (error.response.status === 500) {
      Swal.fire({
        icon: "error",
        title: "Atenção",
        text: "Ocorreu um erro inesperado no sistema, por favor contacte o suporte"
      });
    }

    return Promise.reject(error)
  },
)




const httpFileSystem = axios.create({
  baseURL: process.env.VUE_APP_API_URL_FILESYSTEM,
  headers: {
    'Content-Type': 'application/json',
  },
})

httpFileSystem.interceptors.request.use((config: any) => {
  if (config.headers === undefined)
    return

  if (Authentication.state.value.token) {
    config.headers.Authorization = `Bearer ${Authentication.state.value.token}`
  }

  return config
})

httpFileSystem.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error.response.status === 401) {

      Swal.fire({
        icon: "warning",
        title: "Atenção",
        text: "Sua sessão foi expirada, favor se logar novamente"
      }).then(() => {
        Authentication.actions.logout();
        router.push("/");
      });
    }
    else if (error.response.status === 403) {
      const message = error?.response?.data?.message;
      Swal.fire({
        icon: "warning",
        title: "Atenção",
        text: message
      });
    } else if (error.response.status === 400) {
      const message = error?.response?.data?.message;
      Swal.fire({
        icon: "warning",
        title: "Atenção",
        text: message
      });
    } else if (error.response.status === 500) {
      Swal.fire({
        icon: "error",
        title: "Atenção",
        text: "Ocorreu um erro inesperado no sistema, por favor contacte o suporte"
      });
    }

    return Promise.reject(error)
  },
)
export { http, httpAuth, httpFileSystem }