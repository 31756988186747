 import { ref, computed } from "vue";
import Cookies from "js-cookie";

import { getRoles, login, refreshToken, resetPassword } from "@/core/service/AuthService";

import { getBy } from "@/core/service/UserService";
import * as storage from "./storage"
// import * as Types from "@/types";
import { Credential } from "@/core/model";
import { Data, Login, Payload } from "@/core/model/LoginResponse";
import swal from 'sweetalert2';
let token = localStorage.getItem("token");

if (token) token = JSON.parse(String(localStorage.getItem(token)));

const state = ref<Payload>({});
const showCookies = ref(true)

function ActionSetUser(payload: Payload) {
  state.value = payload;
  storage.setLocalUsuario(payload)
}

function ActionSetToken(payload: Login | Data) {

  if (!state.value) {
    state.value = {};
  }

  if ('status' in payload) {
    storage.setLocalToken(payload.data);
    state.value.token = payload.data.access_token;
  } else {
    storage.setLocalToken(payload);
    state.value.token = payload.access_token;
  }

}

const actions = {

  ActionSetshowCookies(value: boolean) {
    showCookies.value = value;
  },
  ActionRejectCookies() {
    Cookies.set("acceptCookies", false, { expires: 3 });
    showCookies.value = false;
  },
  ActionAcceptCookies() {
    Cookies.set("acceptCookies", true, { expires: 3 });
    showCookies.value = false;

  },

  hasPermission: (path: string, action: string): boolean => {
    const data = state.value.roles?.find(f => f.name.toLowerCase() == 'mk'.toLowerCase())
    const functionaty = data?.data.functionalities.find(f => f.path == path);
    return functionaty?.actions?.includes(action);
  },
  login: async (credentials: Credential) => {

    const user = await login(credentials);
    if (user.status == 'success') {
      if (!(user.data.payload.role ?? []).find(f => f.includes("MK"))) {
        swal.fire({
          icon: "warning",
          text: "Usuário não possuiu acesso ao Sistema MK",
          title: "Atenção"
        });
        throw new Error("Usuário não possuiu acesso ao Sistema MK")
      }

      ActionSetToken(user);
      const roles = await getRoles(String(user.data.payload.id));
      const mkUser = await getBy(String(user.data.payload.id));
      user.data.payload.mkId = mkUser.data.id;
      if (roles.status == 'success') {
        user.data.payload.roles = roles.data?.roles;
        user.data.payload.token = user.data.access_token;
        user.data.payload.refresh_token = user.data.refresh_token
        ActionSetUser(user.data.payload);
      }

    }


  },
  setUser: async (payload: Payload) => {
    ActionSetUser(payload);
  },
  logout: () => {

    localStorage.removeItem("token");
    localStorage.removeItem("usuario");
    localStorage.removeItem("credentials");
    state.value = {};
    storage.deleteLocalToken();
    storage.deleteLocalUsuario();
  },

  forgotPwd: async (email: string) => {
    const data = await resetPassword(email);
    return data;
  },
  async refreshToken() {
    state.value.token = state.value.refresh_token;
    const data = await refreshToken()
    ActionSetToken(data);
    state.value.refresh_token = data.refresh_token;
  },

  ActionCheckToken() {

    try {
      const token = storage.getLocalToken();
      if (state.value.token) {
        return Promise.resolve(state.value.token);
      } else if (!token) {
        return this.logout()
      } else {
        ActionSetToken(token);
        ActionSetUser(storage.getLocalUsuario()); ''
        return Promise.resolve(token);
      }
    } catch (error) {
      actions.logout();
    }

  },
};
const getters = {
  getShowCookies: computed(() => {
    return showCookies.value
  }),
  getStatusLoggedIn: computed(() => {
    return !!(state.value && state.value.token)
  }),
  getToken: computed(() => {
    return state.value.token;
  }),
  getUser: computed(() => {
    return state.value;
  }),
  getRoleMk: computed(() => {
    return (state.value.roles ?? []).find(f => f.name.toLowerCase() == 'mk');
  }),
  isMaster: computed(() => {
    return (state.value.roles ?? []).filter(f => f.name.toLowerCase() == 'mk' && f.data.role.toLowerCase().includes('master'.toLowerCase())).length > 0;
  }),
  isAdmin: computed(() => {
    return (state.value.roles ?? []).filter(f => f.name.toLowerCase() == 'mk' && f.data.role.toLowerCase().includes('admin'.toLowerCase())).length > 0;
  }),
  isManager: computed(() => {
    return (state.value.roles ?? []).filter(f => f.name.toLowerCase() == 'mk' && f.data.role.toLowerCase().includes('Manager'.toLowerCase())).length > 0;
  }),
  isCollaborator: computed(() => {
    return (state.value.roles ?? []).filter(f => f.name.toLowerCase() == 'mk' && f.data.role.toLowerCase().includes('collaborator'.toLowerCase())).length > 0;
  })
};
export { getters, state, actions };
